// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.react-daterange-picker__wrapper {
	height: 38px;
	border: 1px solid #b1b7c1!important;
	border-radius: 7px;
}

.rdt_Table {
	border: 1px solid lightgray;
}

.rdt_TableHead {
	min-height: 40px;
}

.data-table-container-custom {
	position: relative;
	header {
		padding: 0px;
	}
	.rdt_TableRow:nth-child(2n) {
		background-color: #f9f9f9;
	}
	.rdt_TableHeadRow {
		min-height: 40px;
	}
	.search-key {
		max-width: 250px;
	}
	@media (max-width: 865px) {
		.search-key {
			max-width: 100%;
		}
		.react-daterange-picker {
			width: 100%;
		}
	}
}

.form-control:focus {
	border-color: #b1b7c1;
	box-shadow: none;
}

.form-select:focus {
	border-color: #b1b7c1;
	box-shadow: none;
}